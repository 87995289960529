import type { FunctionComponent } from 'react';
import { ContentSwitcher, Dropdown, FormLabel, Switch } from '@carbon/react';
import { getSelectableOptions } from '../../../utils/reportUtils';
import { getSelectedItem } from '../../../utils';

export interface UpdateDropdownSelectionParameters {
  selectedItem: string;
  dropdownId: number;
  dropdownConfig?: DropdownConfig[];
  fetch: boolean;
}

interface VisualDropdownProps {
  readonly dropdown: DropdownConfig;
  readonly dropdownConfig: DropdownConfig[];
  readonly dropdownSelection: string;
  readonly dropdownElementId?: string;
  readonly hideLabel?: boolean;
  readonly size?: 'sm' | 'md' | 'lg';
  readonly readOnly?: boolean;
  readonly updateDropdownSelection: (
    parameters: UpdateDropdownSelectionParameters
  ) => void;
}

const VisualDropdown: FunctionComponent<VisualDropdownProps> = ({
  dropdown,
  dropdownConfig,
  dropdownSelection,
  hideLabel,
  dropdownElementId,
  size,
  readOnly,
  updateDropdownSelection,
}) => {
  const { id, label, fetch, switcher } = dropdown;
  const items = getSelectableOptions(dropdownConfig, id, dropdownSelection);
  const selectedItem = getSelectedItem(id, dropdownSelection, dropdownConfig);
  const dropdownSize = size ?? (hideLabel ? 'md' : 'sm');
  const elementId = dropdownElementId ?? `report-dropdown-${id}`;

  if (switcher) {
    const selectedIndex = items.findIndex((item) => item === selectedItem);
    return items.length > 1 ? (
      <ContentSwitcher
        selectedIndex={selectedIndex}
        data-testid={`report-dropdown-switcher-${id}`}
        size={dropdownSize}
        onChange={({ index }) => {
          updateDropdownSelection({
            selectedItem: items[index],
            dropdownId: id,
            dropdownConfig,
            fetch,
          });
        }}
      >
        {items.map((item) => (
          <Switch key={item} name={item}>
            {item}
          </Switch>
        ))}
      </ContentSwitcher>
    ) : null;
  }

  return items.length > 1 && !readOnly ? (
    <Dropdown
      size={dropdownSize}
      id={elementId}
      key={elementId}
      data-testid={elementId}
      titleText={label}
      label={label}
      items={items}
      hideLabel={hideLabel}
      selectedItem={selectedItem}
      onChange={({ selectedItem }) => {
        updateDropdownSelection({
          selectedItem,
          dropdownId: id,
          dropdownConfig,
          fetch,
        });
      }}
    />
  ) : (
    <div
      className={`visual-dropdowns__one-option ${
        hideLabel || dropdownSize === 'md'
          ? 'visual-dropdowns__one-option--md'
          : ''
      }`}
    >
      {!hideLabel && <FormLabel>{label}</FormLabel>}
      <div>{selectedItem}</div>
    </div>
  );
};

export default VisualDropdown;
