import { useContext, useState } from 'react';
import type { FunctionComponent } from 'react';
import { Button, InlineNotification } from '@carbon/react';
import { DataCategorical } from '@carbon/icons-react';
import IconTitle from '../IconTitle';
import { AttributePlaceholder } from './AttributePlaceholder';
import { ModalContext } from '../../providers/ModalProvider';
import { CreateAttributeModal } from './CreateAttributeModal';
import Fetch from '../Fetch';
import { CACHE_KEY } from '../../constants/api';
import { AppContext } from '../../providers/AppProvider';
import type { CustomAttribute } from '../../types/customAttribute';
import { AttributeList } from './AttributeList';

export const AttributeManager: FunctionComponent = () => {
  const { bannerId, groupId, queryCache, clearCacheForKey } =
    useContext(AppContext);
  const { renderComponent, toggleModal } = useContext(ModalContext);

  const [attributeList, setAttributeList] = useState<CustomAttribute[] | null>(
    null
  );

  const openCreateAttributeModal = () =>
    renderComponent(
      <CreateAttributeModal
        onClose={(isSuccess) => {
          if (isSuccess) {
            // TODO: This will use websockets in the future
            clearCacheForKey(CACHE_KEY.CUSTOM_ATTRIBUTES);
          }

          toggleModal(false);
        }}
      />,
      'CreateAttributeModal'
    );

  return (
    <div className="AttributeManager">
      <div className="AttributeManager__Header" data-testid="page-header">
        <IconTitle
          icon={<DataCategorical size={20} />}
          title="Attribute Manager"
          kind="primary"
          description="Create and manage your custom product attributes here"
          help={
            <>
              <div>Step 1: Create an Attribute.</div>
              <div>
                Step 2: Assign or update attribute values to SKUs by uploading
                them via excel
              </div>
            </>
          }
        />
        <Button
          kind="primary"
          size="md"
          renderIcon={DataCategorical}
          className="has-icon"
          onClick={openCreateAttributeModal}
        >
          Create Attribute
        </Button>
      </div>
      <Fetch
        alwaysFetchOnMount={
          !queryCache[CACHE_KEY.CUSTOM_ATTRIBUTES] || !attributeList
        }
        apiUrl={`/configs/${bannerId}/user-groups/${groupId}/custom-attributes`}
        initialData={null}
        loadingMessage="Loading custom attributes..."
        cacheKey={CACHE_KEY.CUSTOM_ATTRIBUTES}
        onReceiveData={(data: CustomAttribute[] | null) => {
          if (data) {
            setAttributeList(data);
          }
        }}
      >
        {attributeList && attributeList.length === 0 && (
          <AttributePlaceholder
            onCreateAttributeClick={openCreateAttributeModal}
          />
        )}
        {attributeList && attributeList.length > 0 && (
          <>
            <InlineNotification
              kind="info"
              title="You can find SKUs with missing attribute values using the Dimension Reference Report"
              className="AttributeManager__Notification"
              lowContrast
              hideCloseButton
            />
            <AttributeList data={attributeList} />
          </>
        )}
      </Fetch>
    </div>
  );
};
