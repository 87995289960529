import type { CarbonIconType } from '@carbon/icons-react';
import {
  Workspace,
  Home,
  ChartCombo,
  Certificate,
  Help,
  Idea,
  Star,
  ReportData,
  EventSchedule,
  Calendar,
  Share,
  TrashCan,
  SettingsAdjust,
  DataCategorical,
} from '@carbon/icons-react';
import AIIcon from '../assets/icons/AIIcon';
import type { FeatureFlags } from '../types/featureFlag';

export const PAGE_TITLE = process.env.REACT_APP_PLATFORM_NAME || 'OneViu';
export type AppSection =
  | 'home'
  | 'modules'
  | 'workspace'
  | 'workspace/my-reports'
  | 'workspace/archived'
  | 'custom-groups'
  | 'attribute-manager'
  | 'oneviuai-questions';
interface SidebarSection {
  name: string;
  icon?: CarbonIconType;
  navigateTo?: AppSection;
  href?: string;
  rel?: string;
  items?: SidebarSection[];
  hide?:
    | boolean
    | ((data: { user: User; featureFlags: (keyof FeatureFlags)[] }) => boolean);
  hasDivider?: boolean;
}

export const APP_SECTIONS: SidebarSection[] = [
  { name: 'Home', navigateTo: 'home', icon: Home },
  { name: 'Insight Modules', navigateTo: 'modules', icon: ChartCombo },
  {
    name: 'My Workspace',
    navigateTo: 'workspace',
    icon: Workspace,
  },
  {
    name: 'Custom Groups',
    navigateTo: 'custom-groups',
    icon: SettingsAdjust,
    hasDivider: true,
  },
  {
    name: 'Attribute Manager',
    navigateTo: 'attribute-manager',
    icon: DataCategorical,
    hide: ({ user, featureFlags }) =>
      featureFlags.includes('custom-attributes') &&
      (user?.user_type === USER_TYPE.SUPPORT ||
        user?.user_role === USER_ROLE.SUPER_USER),
  },
  {
    name: 'Oneviu AI Questions',
    navigateTo: 'oneviuai-questions',
    icon: AIIcon,
    hide: ({ user }) =>
      user?.user_type === USER_TYPE.SUPPORT &&
      user?.user_role === USER_ROLE.SUPER_USER,
  },
];

export const supportSection = (
  productFruitsUserData: string | null
): SidebarSection[] => [
  {
    href: `https://help.oneviu.ai/en?hmac=${productFruitsUserData}`,
    name: 'Help Centre',
    icon: Idea,
    rel: 'help external noreferrer noopener',
    hide: !process.env.REACT_APP_PRODUCT_FRUITS_KEY,
  },
  {
    name: 'Helpdesk',
    icon: Help,
    href: 'mailto:helpdesk@oneviu.ai',
    rel: 'help external noreferrer noopener',
  },
  {
    name: 'Feedback',
    icon: Certificate,
    href: 'mailto:feedback@oneviu.ai',
    rel: 'feedback external noreferrer noopener',
  },
];

export const LEGENDS = [
  {
    label: 'Strong Association',
    value: ' SOR > 120',
    background: '#E6F4E9',
    color: '#0E6027',
  },
  {
    label: 'Strong Disassociation',
    value: ' SOR < 80',
    background: '#FFEBEE',
    color: '#BB1717',
  },
  {
    label: 'Strong Association Low Confidence',
    value: ' SOR > 120',
    background: 'transparent',
    color: '#0E6027',
  },
  {
    label: 'Strong Disassociation Low Confidence',
    value: ' SOR < 80',
    background: 'transparent',
    color: '#BB1717',
  },
];

export const PAGINATION_PAGE_SIZES = [100, 200, 500] as const;

export const DELETE_REPORT_MODAL_TITLE = 'Reports to be deleted';

export enum RepeatPeriod {
  ONE_TIME = 'One-time',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum USER_TYPE {
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier',
  SUPPORT = 'support',
}

export enum USER_ROLE {
  STANDARD_USER = 'standard_user',
  SUPER_USER = 'super_user',
  SUPER_ADMIN = 'super_admin',
}

export enum TERMS_STATUS {
  ACCEPTED = 'accepted',
  NOT_ACCEPTED = 'not_accepted',
  REJECTED = 'rejected',
}

export enum EndsRecurrenceType {
  NEVER = 'never',
  OCCURRENCES = 'occurrences',
  CUSTOM_DATE = 'custom-date',
}

export enum ReportAttribute {
  Filter = 'filter',
  Universe = 'universe',
}

export const repeatOptions = [
  RepeatPeriod.DAILY,
  RepeatPeriod.WEEKLY,
  RepeatPeriod.MONTHLY,
];

export const advancedOptionsLabels = {
  maxRepeat: {
    label: 'Top Repeat Band Value',
  },
  series: {
    label: 'Dimension Selected As Series',
  },
  lfl_type: {
    label: 'Like For Like Added',
  },
  fresh: {
    label: 'Fresh',
  },
  time_split: {
    label: 'Time Series Type(s)',
  },
  vennOption: {
    label: 'Dimension Selected As Venn Overlaps',
  },
  max_n_attog: {
    label: 'Number Of Groups',
  },
  sku_lvl: {
    label: 'Roll Down To SKU Level',
  },
  followOutId: {
    label: 'Linked Report',
  },
  skuSales: {
    label: 'Exclude SKUs that have $0 sales or less',
  },
  spnd_max: {
    label: 'Top Spend Band Value',
    labelFormat: {
      format: 'currency',
      currency: 'USD',
    },
  },
  spnd_incr: {
    label: 'Spend Band Intervals',
    labelFormat: {
      format: 'currency',
      currency: 'USD',
    },
  },
  itm_max: {
    label: 'Top Unit Band Value',
    labelFormat: {
      format: 'integer',
    },
  },
  itm_incr: {
    label: 'Unit Band Intervals',
    labelFormat: {
      format: 'integer',
    },
  },
  weeksSelling: {
    label: 'Minimum Weeks on Sale Value',
    labelFormat: {
      format: 'integer',
    },
  },
  weeksLastSold: {
    label: 'Minimum Weeks Since Sale Value',
    labelFormat: {
      format: 'integer',
    },
  },
  storesCutoff: {
    label: 'Minimum Number of Store Selling',
    labelFormat: {
      format: 'integer',
    },
  },
  skuCusts: {
    label: 'Minimum Number of Members Buying',
    labelFormat: {
      format: 'integer',
    },
  },
};

export const reportTableTabOptions = {
  'my-reports': {
    emptyNotification: 'No Reports Found',
    emptyMessage:
      'It looks like you haven’t run any reports yet. Click below to run your first report.',
    navigateTo: '/modules',
    tabKey: 'my-reports',
    buttonText: 'Request reports',
    icon: ReportData,
    posthogEventOrigin: 'My Reports Table',
  },
  favourites: {
    emptyNotification: 'No Favourites Found',
    emptyMessage: (
      <>
        It looks like you don’t have any favourites yet. To favourite a report,
        go to My Reports and select <Star size={14} /> Add to Favourites.
      </>
    ),
    navigateTo: '/workspace/my-reports',
    tabKey: 'favourites',
    buttonText: 'View My Reports',
    icon: Star,
    posthogEventOrigin: 'Favourite Reports Table',
  },
  shared: {
    emptyNotification: 'No Shared Reports Found',
    emptyMessage:
      'It looks like no reports have been shared with you yet. Click below to view all of your reports.',
    navigateTo: '/workspace/my-reports',
    tabKey: 'shared',
    buttonText: 'View My Reports',
    icon: Share,
    posthogEventOrigin: 'Shared Reports Table',
  },
  scheduled: {
    emptyNotification: 'No Scheduled Reports Found',
    emptyMessage: (
      <>
        It looks like you haven’t schedule any reports yet. To schedule a
        report, go to My Reports and select <EventSchedule size={14} />{' '}
        Schedule.
      </>
    ),
    navigateTo: '/workspace/my-reports',
    tabKey: 'scheduled',
    buttonText: 'View My Reports',
    icon: Calendar,
    posthogEventOrigin: 'Scheduled Reports Table',
  },
};

export const workspaceTabs = [
  {
    label: 'My reports',
    icon: ReportData,
    path: 'my-reports',
  },
  {
    label: 'Favourites',
    icon: Star,
    path: 'favourites',
  },
  {
    label: 'Scheduled',
    icon: Calendar,
    path: 'scheduled',
  },
  {
    label: 'Shared',
    icon: Share,
    path: 'shared',
  },
  {
    label: 'Deleted',
    icon: TrashCan,
    path: 'archived',
  },
];

export const workspaceSharingTabs = [
  {
    key: 'my-shares',
    label: 'My Shared Reports',
  },
  {
    key: 'shared-with-me',
    label: 'Shared with Me',
  },
] as const satisfies ReadonlyArray<{ key: string; label: string }>;

export const custGroupTableHeaders = [
  { key: 'label', header: 'Label' },
  { key: 'entity_no', header: 'ID (Alphanumericals)' },
  { key: 'customGroup', header: 'Group' },
];

export const alphanumericPattern = /[^a-zA-Z0-9]/g;
export const semicolonPattern = /;/g;
export const subsKeyRegex = /^subs_\d+_name$/;
export const EXCEL_SHEET_NAME_LIMIT = 31;

export const SUBS_HEADER_KEYS = [
  'subs_1_name',
  'subs_1_tfr',
  'subs_2_name',
  'subs_2_tfr',
  'subs_3_name',
  'subs_3_tfr',
  'subs_4_name',
  'subs_4_tfr',
  'subs_5_name',
  'subs_5_tfr',
];

export const RUN_ID_LENGTH = 36;

export enum CustomGroupAccess {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}

export const CUSTOM_GROUP_ALL_GROUPS = 'All Groups';

export enum AuthenticationType {
  EMAIL_PASSWORD = 'email-password',
  EMAIL_OTP = 'passwordless-email',
  SSO = 'sso',
}
interface DownloadOption {
  id: number;
  label: string;
  fileTypes: ('csv' | 'xlsx')[];
  exportAll: boolean;
  headers?: 'all' | 'visible';
}

export const initialDownloadOptions: DownloadOption[] = [
  {
    id: 0,
    label: 'All data table variants (All drop-downs)',
    fileTypes: ['xlsx'],
    exportAll: true,
  },
  {
    id: 1,
    label: 'Selected data variant with all columns',
    fileTypes: ['xlsx', 'csv'],
    exportAll: false,
    headers: 'all',
  },
  {
    id: 2,
    label: 'Selected data variant with only visible columns',
    fileTypes: ['xlsx', 'csv'],
    exportAll: false,
    headers: 'visible',
  },
];

export const USER_DATA_REQUIRED_FIELDS = [
  'name',
  'email',
  'banner',
  'user_group',
  'measure_access',
  'user_type',
  'user_role',
  'authentication',
];

export const USER_DATA_OPTIONAL_FIELDS = [
  'job_title',
  'department',
  'supplier_name',
];
