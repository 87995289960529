export enum AttributeType {
  String = 'string',
}

export enum AttributeStatus {
  Created = 'created',
  Creating = 'creating',
  CreationFailed = 'creation_failed',
  Deleting = 'deleting',
  DeletionFailed = 'deletion_failed',
  Updated = 'updated',
  UpdatingFailed = 'update_failed',
}

export interface CustomAttribute {
  readonly attribute: string;
  readonly attribute_id: string;
  readonly attribute_type: AttributeType;
  readonly sku_count: number;
  readonly last_updated_timestamp: string;
  readonly last_updated_user: string;
  readonly status: AttributeStatus;
}
