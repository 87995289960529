import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import XRangeModule from 'highcharts/modules/xrange';
import HC_exporting from 'highcharts/modules/exporting';
import HC_more from 'highcharts/highcharts-more';
import HC_boost from 'highcharts/modules/boost';
import { ChartContext } from '../../providers/ChartProvider';
import { ReportContext } from '../../providers/ReportProvider';
import { getChartHeight, getMergedOptions } from '../../utils/chartUtils';
import { HIGHCHARTS_PALETTE } from '../../constants/palettes';

HC_exporting(Highcharts);
HC_more(Highcharts);
HC_boost(Highcharts);
XRangeModule(Highcharts);

Highcharts.setOptions({
  accessibility: {
    enabled: false,
  },
  colors: HIGHCHARTS_PALETTE,
  chart: {
    style: {
      fontFamily: 'Inter',
    },
  },
  credits: {
    enabled: false,
  },
  boost: {
    seriesThreshold: 500,
  },
  title: {
    text: '',
  },
  tooltip: {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: 'white',
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
});

interface ChartProps extends HighchartsReact.Props {
  useRef?: boolean;
  id?: string;
  reportTemplateId: string;
  isVisible?: boolean;
}

const Chart: FunctionComponent<ChartProps> = ({
  options,
  id,
  reportTemplateId,
  isVisible = true,
}) => {
  const { chartOptions } = useContext(ChartContext);
  const {
    fullscreenVisual,
    tabIndex,
    reportConfig,
    secondaryDropdownSelections,
  } = useContext(ReportContext);
  const [height, setHeight] = useState(400);

  const secondaryAxis = useMemo(
    () =>
      secondaryDropdownSelections?.[id] ?? {
        isActive: false,
      },
    [secondaryDropdownSelections]
  );

  useEffect(() => {
    if (
      id &&
      reportConfig.configuration.switchers[reportTemplateId] &&
      fullscreenVisual === null &&
      isVisible
    ) {
      const visualIdsToDisplay =
        reportConfig.configuration.switchers[reportTemplateId][tabIndex]
          ?.visualContent ?? [];

      const visualsToDisplay = reportConfig.configuration.visuals[
        reportTemplateId
      ].filter((vis) => visualIdsToDisplay.includes(vis.id));
      setHeight(getChartHeight(height, visualsToDisplay, id) ?? height);
    }
  }, [isVisible]);

  return isVisible ? (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...getMergedOptions(
          options,
          chartOptions,
          fullscreenVisual !== null,
          height,
          secondaryAxis.isActive
        ),
      }}
    />
  ) : null;
};

export default Chart;
